<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table noDataText="Aucune donnée" multiple stripe v-model="selected2" pagination max-items="50" search :data="proformas">
              <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button v-if="activeUserInfos.privileges.find(item => item === 'proforma')&&activeUserInfos.privileges.find(item => item === 'create')" class="mb-4 ml-5 mr-5 md:mb-0" @click="$router.push('/apps/proformaclient/add')">Nouvelle proforma</vs-button>
                <template v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                  <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectProforma()">
                    <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                  </vs-select>
                </template>
                <vue-simple-suggest
                  pattern="\w+"
                  v-model="model"
                  :list="getProformaList"
                  :max-suggestions="10"
                  :min-length="3"
                  :debounce="200"
                  :filter-by-query="false"
                  :prevent-submit="true"
                  :controls="{
                    selectionUp: [38, 33],
                    selectionDown: [40, 34],
                    select: [13, 36],
                    hideList: [27, 35]
                  }"
                  :mode="mode"
                  :nullable-select="true"
                  ref="suggestComponent"
                  placeholder="Search information..."
                  value-attribute="id"
                  display-attribute="numProforma"
                  @select="onSuggestSelect">

                  <div class="g">
                    <input type="text" placeholder="Recherche proforma: N°Proforma">
                  </div>

                  <template slot="misc-item-above" slot-scope="{ suggestions, query }">
                    <div class="misc-item">
                      <span>Vous recherchez la proforma N°: '{{ query }}'.</span>
                    </div>

                    <template v-if="suggestions.length > 0">
                      <div class="misc-item">
                        <span>{{ suggestions.length }} suggestions sont affichées...</span>
                      </div>
                      <hr>
                    </template>

                    <div class="misc-item" v-else-if="!loading">
                      <span>Aucun résultat</span>
                    </div>
                  </template>

                  <div slot="suggestion-item" slot-scope="scope" :title="`${scope.suggestion.numProforma}`">
                    <div class="text">
                      <span v-html="boldenSuggestion(scope)"></span>
                    </div>
                  </div>

                  <div class="misc-item" slot="misc-item-below" slot-scope="{}" v-if="loading">
                    <span>Chargement...</span>
                  </div>
                </vue-simple-suggest>

              </template>
              <template slot="thead">
                <vs-th sort-key="_id">
                  N°Proforma
                </vs-th>
                <vs-th sort-key="Idprescripteur">Date</vs-th>
                <vs-th sort-key="Idprescripteur">Client</vs-th>
                <vs-th sort-key="Idprescripteur">Prescripteur</vs-th>
                <vs-th sort-key="proposition1">Proposition 1</vs-th>
                <vs-th sort-key="proposition2">Proposition 2</vs-th>
                <vs-th sort-key="proposition2">Proposition 3</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      {{tr.numProforma }}
                    </vs-td>

                    <vs-td>
                      {{tr.DateCommd | moment("calendar", "July 10 2011") }}
                    </vs-td>

                    <vs-td>
                      {{ getClientFullName(tr.IdClient) }}
                    </vs-td>

                    <vs-td>
                      <p class="ordonnance-prescripteur">{{ getPrescripteurName(tr.Idprescripteur) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-price">{{ propositionUn(tr) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="date">{{ propositionDeux(tr) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="date">{{ propositionTrois(tr) }}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon title="Passez la commande" icon="ShoppingBagIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="passerCommande(tr._id)" />
                      <feather-icon title="Details" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="showProforma(tr._id)" />
                      <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-2" @click.stop="editProforma(tr._id)" />
                      <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr._id)" />
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import axios from '@/axios.js'
export default {
  data () {
    return {
      model: null,
      mode: 'input',
      loading: false,
      chosen: '',
      selected: null,
      selected2:[],
      ordonnances:[],
      ordonnanceId: '',
      idOfficine: null,
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      idProforma: null
    }
  },
  components: {
    VueSimpleSuggest
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    proformas () {
      return this.$store.state.proforma.proformas
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    clients () {
      return this.$store.state.client.clients
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },

  methods: {
    editProforma (id) {
      this.$router.push(`/apps/proforma-client/edit/${id}`).catch((err) => { console.log(err) })
    },
    showProforma (id) {
      this.$router.push(`/apps/proforma-client/details/${id}`).catch((err) => { console.log(err) })
    },
    passerCommande (id) {
      this.$router.push(`/apps/proforma-commande/${id}`).catch((err) => { console.log(err) })
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    confirmDeleteRecord (id) {
      this.idProforma = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Proforma suppression',
        text: 'Vous allez supprimer la proforma selectionnée',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('proforma/removeProforma', this.idProforma)
        .then(() => {
          this.$vs.notify({
            title: 'Proforma Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getProformaList (inputValue) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/proforma-full-search/${this.idOfficine}/${inputValue}`)
          .then((response) => {
            /* const fields = ['Nom', 'Prenoms'] */
            const result = response.data
            console.log('result', result)
            resolve(result)
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
          })

      })
    },
    onSuggestSelect (suggest) {
      this.selected = suggest
      this.$store.commit('proforma/ADD_SEARCH_PROFORMA', suggest)
    },
    boldenSuggestion (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) return result
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
      return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
    },
    getPrescripteurName (id) {
      const pres = this.$store.getters['prescripteur/getPrescripteurById'](id)
      return pres ? `${pres.NomPresc ? pres.NomPresc : ''} ${pres.PrenomPresc ? pres.PrenomPresc : ''}` : ''
    },
    getClientFullName (id) {
      const client = id ? this.$store.getters['client/getClientById'](id) : null
      if (client) {
        return `${client ? (client.Nom ? client.Nom : '') : ''} ${client ? (client.Prenoms ? client.Prenoms : '') : ''}`
      } else if (id) {
        this.$store.dispatch('client/getClientById', id)
          .then((resp) => {
            this.$store.commit('client/ADD_CLIENT', resp.data)
            const client = this.$store.getters['client/getClientById'](id)
            return `${client ? (client.Nom ? client.Nom : '') : ''} ${client ? (client.Prenoms ? client.Prenoms : '') : ''}`
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    propositionUn (data) {
      const PrixBrute = +(data.PrixMont1 ? data.PrixMont1 : 0) + (+(data.PrixVerG1 ? data.PrixVerG1 : 0) + +(data.PrixVerD1 ? data.PrixVerD1 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    },
    propositionDeux (data) {
      const PrixBrute = +(data.PrixMont2 ? data.PrixMont2 : 0) + (+(data.PrixVerG2 ? data.PrixVerG2 : 0) + +(data.PrixVerD2 ? data.PrixVerD2 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    propositionTrois (data) {
      const PrixBrute = +(data.PrixMont3 ? data.PrixMont3 : 0) + (+(data.PrixVerG3 ? data.PrixVerG3 : 0) + +(data.PrixVerD3 ? data.PrixVerD3 : 0))
      const montantRemise = ((data.RemiseCom ? data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {

        })
        .catch((err) => {
          console.log(err)

        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => {
          console.log(err)
        })
    },
    activeUserInfo () {
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getClientsOfficine(this.idOfficine)
      this.getProformaByOfficine(this.idOfficine)
    },
    CurrentOfficineSelectProforma () {
      this.getProformaByOfficine(this.idOfficine)
    },
    getProformaByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('proforma/getProformaByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    this.activeUserInfo()
    /* if (!localStorage.getItem('prescripteurs')) this.getPrescripteurs() */
    if (!(this.$store.state.prescripteur.prescripteurs.length > 0)) this.getPrescripteurs()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
  }
}
</script>

